<template>
<!-- v-if="getLocationMember && getActiveLocation && !showUsersLoader" -->
    <div v-if="getLocationMember && getActiveLocation && !showUsersLoader">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end mt-n1"  >
                        <div class="col-auto mb-n5">
                            <!-- Avatar -->
                            <div class="avatar avatar-xxl header-avatar-top">
                                <img
                                    v-if="getLocationMember.MediumImageUrl"
                                    :src="getLocationMember.MediumImageUrl"
                                    alt="..."
                                    class="
                                        avatar-img-profile
                                        rounded-circle
                                        border border-4 border-body
                                    "
                                />
                                <span
                                    style="display: block !important;  background-color: transparent !important;"
                                    class="
                                        avatar-title
                                        rounded-circle
                                        border border-4 border-body
                                    "
                                    v-else
                                >
                                    <!-- class="mt-4" <i class="fi flaticon-user" style="margin-left: 6px !important;"></i> style="width: 32px; height: 32px; margin-left: 6px !important;" -->
                                    <img :src="'/images/person-big_MyZesec_140dp.png'" style="width: 98px; height: 98px;"/>
                                </span>
                            </div>
                        </div>
                        <!-- ml-n1 -->
                        <div class="col mb-1 ml-md-n5">
                            <!-- Pretitle -->
                            <h6 class="header-pretitle">
                                {{ $t("users.profile") }}
                            </h6>

                            <!-- Title -->
                            <h1 class="header-title">
                                {{ userFName(getLocationMember.FirstName) }}
                                {{ userLName(getLocationMember.LastName) }}
                            </h1>
                        </div>
                        <!--  v-if="$can('manageUsers', getActiveLocation)" -->
                        <div
                            class="col col-md-auto mt-2 mt-md-0 mb-md-3"                           
                        >
                            <b-dropdown
                                :text="$t('buttons.manage')"
                                right
                                variant="primary"
                                toggle-tag="a"
                                class="float-right"
                            >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deleteUser(getLocationMember)"
                                    >{{ $t("buttons.remove_from_location") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="createInvite"
                                    >{{ $t("staff.invite") }}</b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                    </div>
                    <!-- / .row -->
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Nav -->
                            <ul class="nav nav-tabs nav-overflow header-tabs">
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 0"
                                        v-bind:class="{ active: tabIndex == 0 }"
                                        >{{ $t("users.profile") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 1"
                                        v-bind:class="{ active: tabIndex == 1 }"
                                        >{{ $t("keys.keys") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <!-- v-if="
                                            $can('viewDoors', getActiveLocation)
                                        " -->
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 2"
                                        v-bind:class="{ active: tabIndex == 2 }"
                                        
                                        >{{ $t("doors.doors") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 3"
                                        v-bind:class="{ active: tabIndex == 3 }"
                                        >{{ $t("staff.role") }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- / .header-body -->
            </div>
        </div>
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <b-card v-show="tabIndex == 0" transition="fade">
                        <template v-slot:title>
                            {{ $t("users.profile") }}
                        </template>
                        <div class="card-body">
                            <b-row>
                                <strong class="mx-2"
                                    >{{ $t("users.phone") }}:</strong
                                >
                                {{ getLocationMember.PhoneNumber }}
                            </b-row>
                            <b-row>
                                <strong class="mx-2">Email:</strong>
                                {{ getLocationMember.Email }}
                            </b-row>
                        </div>
                    </b-card>
                    <b-card
                        v-show="tabIndex == 1"
                        transition="fade"
                        body-class="px-0"
                    >
                        <zs-list-transition :showLoader="showKeysLoader">
                            <template v-slot:content>
                                <key-list-item
                                    v-for="doorKey in getLocationMemberKeys"
                                    v-bind:key="doorKey.KeyId +
                                            '-' +
                                            doorKey.UserId +
                                            '-' +
                                            doorKey.CalendarId || ''
                                    "
                                    :doorKey="doorKey"
                                    :showDeleting="true"
                                    class="flex-column align-items-start"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('users.no_keys')" />
                            </template>
                        </zs-list-transition>
                    </b-card>
                    <!-- v-if="$can('viewDoors', getActiveLocation)" -->
                    <b-card
                        v-show="tabIndex == 2"
                        
                        transition="fade"
                        body-class="px-0"
                    >
                        <zs-list-transition :showLoader="showDoorsLoader">
                            <template v-slot:content>
                                <location-door-list-item
                                    v-for="device in getLocationMemberDevices"
                                    v-bind:key="device.Device.Id"
                                    :device="device"
                                    class="flex-column align-items-start"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('users.no_doors')" />
                            </template>
                        </zs-list-transition>
                    </b-card>
                    <b-card
                        v-show="tabIndex == 3"
                        transition="fade"
                        body-class="px-0"
                    >
                        <zs-list-transition :showLoader="showRolesLoader">
                            <template v-slot:content>
                                <user-role-item-setting
                                    v-for="(
                                        role, index
                                    ) in getUserRolesFiltered"
                                    :role="role"
                                    v-bind:key="index"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('staff.no_roles')" />
                            </template>
                        </zs-list-transition>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        
        <zs-delete-key-user-modal />
        <zs-delete-company-user-modal />
        <zs-delete-location-user-modal />  
        <zs-company-invite-modal
            v-if="getActiveCompany"
            :company="getActiveCompany"
        /><zs-company-rerole-modal
            v-if="getActiveCompany"
            :company="getActiveCompany"
        />
        <zs-location-invite-modal
            :companyId="$route.params.companyId"
            :locationId="$route.params.locationId"
        />
        <zs-change-location-role-modal
            :companyId="$route.params.companyId"
            :locationId="$route.params.locationId"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import KeyListItem from "@/components/key/KeyListItem";
import LocationDoorListItem from "@/components/device/LocationDoorListItem";
import ListTransition from "@/components/common/ListTransition";
import DeleteLocationUserModal from "@/components/modals/DeleteLocationUser";
import DeleteKeyUserModal from "@/components/modals/DeleteKeyUser";
import EmptyList from "@/components/common/EmptyList";
import ChangeLocationEmployeeRole from "@/components/modals/ChangeLocationEmployeeRole";
import InviteLocationEmployeeModal from "@/components/modals/InviteLocationEmployee";
import UserRoleItemSetting from "@/components/user/UserRoleItemSetting";
import InviteCompanyEmployeeModal from "@/components/modals/InviteCompanyEmployeeAccordion";
import ChangeCompanyEmployeeRole from "@/components/modals/ChangeCompanyEmployeeRole";
import DeleteUserModal from "@/components/modals/DeleteUser";
import activityMixin from "@/mixins/activityMixin";
import {
    MEMBER_GET,
    MEMBER_KEYS_GET,
    MEMBER_DEVICES_GET,
    MEMBER_ROLES_GET,
} from "@/store/types/members";

import {
  COMPANIES_GET,
  COMPANY_GET,
  COMPANY_GET_EMPLOYEES,
} from "@/store/types/companies";

import { LOCATION_GET } from "@/store/types/locations";

import { M_LOCATION_USER_DELETE,
  M_COMPANY_LOCATION_INVITE_FORM,
  M_COMPANY_FORM,
  M_COMPANY_INVITE_FORM,
  M_COMPANY_DELETE,
  M_COMPANY_USER_DELETE 
} from "@/components/modals/types";

import { validationMixin } from "vuelidate";
import modalMixin from "@/mixins/modalMixin";

import { ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY } from "@/acl/roles";

export default {
    mixins: [validationMixin, modalMixin, activityMixin],
    data() {
        return {
            tabIndex: 0,
            showUsersLoader: true,
            showDoorsLoader: true,
            showKeysLoader: true,
            showRolesLoader: true,            
        };
    },
    computed: {
        ...mapGetters([
            "getLocationMember",
            "getLocationMemberKeys",
            "getLocationMemberDevices",
            "getActiveLocation",
            "getLocationMemberRoles",
            "getUser",
            "getUserCompanies",
            "getActiveCompany",
            "getCompanyEmployees",
        ]),
        getUserRolesFiltered() {
            return this.getLocationMemberRoles.filter(
                (item) =>
                    ![ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY].includes(
                        item.Name
                    )
            );
        },
    },    
    created() {
        this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
        this.$store.dispatch(MEMBER_GET, {
            locationId: this.$route.params.locationId,
            userId: this.$route.params.userId,
        })
         .then(() => (this.showUsersLoader = false));
        this.$store
            .dispatch(MEMBER_ROLES_GET, {
                companyId: this.$route.params.companyId,
                userId: this.$route.params.userId,
            })
            .then(() => (this.showRolesLoader = false));
        this.$store
            .dispatch(MEMBER_KEYS_GET, {
                locationId: this.$route.params.locationId,
                userId: this.$route.params.userId,
            })
            .then(() => (this.showKeysLoader = false));
        this.$store
            .dispatch(MEMBER_DEVICES_GET, {
                locationId: this.$route.params.locationId,
                userId: this.$route.params.userId,
            })
            .then(() => (this.showDoorsLoader = false));
    },
    methods: {
        deleteUser(user) {
            if(!user.Location){
                user.Location = this.getActiveLocation
                user.User = this.getLocationMember
            }
            this.showModal({ user: user }, M_LOCATION_USER_DELETE);
        },
        createInvite() {
            this.showModal({ staffInvite: null }, M_COMPANY_LOCATION_INVITE_FORM);
        },
        userFName(FirstName){
            if(FirstName === null || FirstName === "null" || FirstName === ""){
                FirstName = '';
            }            
            return FirstName;
        },
        userLName(LastName){          
            if(LastName === null  || LastName === "null" || LastName === ""){
                LastName = '';
            }
            return LastName;
        },
    },
    destroyed() {},
    components: {
        "zs-list-transition": ListTransition,
        KeyListItem,
        EmptyList,
        LocationDoorListItem,
        "user-role-item-setting": UserRoleItemSetting,
        "zs-delete-location-user-modal": DeleteLocationUserModal,
        "zs-delete-key-user-modal": DeleteKeyUserModal,
        "zs-company-invite-modal": InviteCompanyEmployeeModal,
        "zs-company-rerole-modal": ChangeCompanyEmployeeRole,
        "zs-delete-company-user-modal": DeleteUserModal,
        "zs-location-invite-modal": InviteLocationEmployeeModal,
        "zs-change-location-role-modal": ChangeLocationEmployeeRole,
    },
};
</script>
<style lang="scss" scoped>
// fade
@import "../components/common/PageTransition/styles/transitions/fade/fade";
</style>
